import { BaseFunctions } from "./js/basefunctions.js";
import { BurgerNavi } from "./js/burgernavi.js";
import { RevealItems } from "./js/revealItems.js";

const breakpointSmall = 500;
const breakpointMedium = 768;
const breakpointLargeMedium = 1024;
const breakpointLarge = 1200;
const breakpointXLarge = 1450;

document.addEventListener("DOMContentLoaded", function (event) {
    const basefunctions = new BaseFunctions(breakpointMedium, breakpointLarge, breakpointXLarge);
    basefunctions.init();

    const burgernavi = new BurgerNavi();
    burgernavi.init(breakpointMedium, breakpointLarge, breakpointXLarge);

    const revealitems = new RevealItems(breakpointMedium);
    revealitems.init();

});