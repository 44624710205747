import { gsap } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

export class RevealItems {

    constructor(breakpointMedium) {
        this.breakpointMedium = breakpointMedium;
    }
    init() {
        gsap.registerPlugin(ScrollTrigger);
        let delay = 0.1;
        let startPoint = '100%';
        /*let elemsLeft = gsap.utils.toArray(".wp-block-column:first-of-type .column-layout:not(.no-reveal) figure");
        let startPoint = '100%';
        let delay = 0.1;
        elemsLeft.forEach(elem => {
            gsap.set(elem, { x: -30, alpha: 0 })
            gsap.fromTo(elem, { x: -30, alpha: 0 },
                {
                    scrollTrigger: {
                        trigger: elem,
                        start: "top " + startPoint,
                        //markers: true,
                        toggleActions: "play pause resume reset"
                    },
                    x: 0,
                    alpha: 1,
                    delay: delay,
                    ease: "power4.out",
                    duration: 1
                })
        });
        let elemsRight = gsap.utils.toArray(".wp-block-column:last-of-type .column-layout:not(.no-reveal) figure");
        elemsRight.forEach(elem => {
            gsap.set(elem, { x: 30, alpha: 0 })
            gsap.fromTo(elem, { x: 30, alpha: 0 },
                {
                    scrollTrigger: {
                        trigger: elem,
                        start: "top " + startPoint,
                        //markers: true,
                        toggleActions: "play pause resume reset"
                    },
                    x: 0,
                    alpha: 1,
                    delay: delay,
                    ease: "power4.out",
                    duration: 1
                })
        });*/
    
        let elemsBottom = gsap.utils.toArray(".wp-block-column .column-layout:not(.no-reveal) figure, .column-layout:not(.no-reveal) h2, .column-layout:not(.no-reveal) h3, .column-layout:not(.no-reveal) h4, .column-layout:not(.no-reveal) p, .column-layout:not(.no-reveal) h4, .column-layout:not(.no-reveal) li");
        let dist = 50;
        delay = 0;
        /*if (window.innerWidth < this.breakpointMedium) {
            dist = 50
        } else {
            dist = 50
        }*/
        //dist = 50;
        elemsBottom.forEach(elem => {
            gsap.set(elem, { y: dist, alpha: 0 })
            gsap.fromTo(elem, { y: dist, alpha: 0 },
                {
                    scrollTrigger: {
                        trigger: elem,
                        start: -dist + " " + startPoint,
                        //markers: true,
                        toggleActions: "play pause resume reset"
                    },
                    y: 0,
                    alpha: 1,
                    delay: delay,
                    ease: "power4.out",
                    duration: 1
                })
        });
    }
}