import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollToPlugin);

export class BaseFunctions {
    constructor(breakpointMedium, breakpointLarge, breakpointXLarge) {
        this.breakpointMedium = breakpointMedium;
        this.breakpointLarge = breakpointLarge;
        this.breakpointXLarge = breakpointXLarge;
        this.anchorScroll = false;
    }
    init() {
        this.scrollToAnchorInit();
        this.shrinkLogo();
        window.addEventListener('scroll', this.shrinkLogo.bind(this));
        this.setLogoText();
    }

    setLogoText() {
        if (window.location.href.indexOf("investment") > -1) {
            document.querySelector('.logo .investment').classList.add('show');
            document.querySelector('.logo .partners').classList.remove('show');
            document.querySelector('.logo .partners-3').classList.add('show');
        } else if (window.location.href.indexOf("development") > -1) {
            document.querySelector('.logo .development').classList.add('show');
            document.querySelector('.logo .partners').classList.remove('show');
            document.querySelector('.logo .partners-3').classList.add('show');
        } else if (window.location.href.indexOf("advisory") > -1) {
            document.querySelector('.logo .advisory').classList.add('show');
            document.querySelector('.logo .partners').classList.remove('show');
            document.querySelector('.logo .partners-3').classList.add('show');
        } else {
            document.querySelector('.logo .partners').classList.add('show');
            document.querySelector('.logo .advisory').classList.remove('show');
            document.querySelector('.logo .development').classList.remove('show');
            document.querySelector('.logo .investment').classList.remove('show');
            document.querySelector('.logo .partners-3').classList.remove('show');
        }
    }

    setHeight() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    shrinkLogo(evt) {
        //if (window.innerWidth > this.breakpointMedium) {
        const header = document.querySelector('header');
        //let dir = this.getSCrollDirection();
        if (!document.querySelector('nav.mainnav').classList.contains('opened')) {
            if (window.scrollY > 90) { // 160
                header.classList.add('min');
                document.querySelector('.wp-block-navigation-submenu').addEventListener('mouseover', function() {
                    header.classList.add('extended');
                });
                document.querySelector('.wp-block-navigation-submenu').addEventListener('mouseout', function() {
                    header.classList.remove('extended');
                });
                // document.getElementById('logoText').style.display = 'none';
            } else {
                header.classList.remove('min');
                header.classList.remove('extended');
                //document.getElementById('logoText').style.display = 'block';
            }
        }
        //}
    }
    getSCrollDirection() {
        const self = this;
        let scrollPos = window.scrollY;
        let scrollDirection = '';
        scrollPos = window.scrollY;
        if (scrollPos > this.lastScrollTop) {
            scrollDirection = 'down';
        } else {
            scrollDirection = 'up';
        }
        this.lastScrollTop = scrollPos <= 0 ? 0 : scrollPos;
        return scrollDirection;
    }

    scrollDown() {
        if (document.querySelectorAll('.headerimage .arrow-down').length > 0) {
            document.querySelector('.headerimage .arrow-down').addEventListener('click', function () {
                gsap.to(window, { duration: 1.2, scrollTo: window.innerHeight, ease: "power3.out" });
            });
        }
    }
    scrollToAnchorInit() {

        var self = this;
        Array.from(document.querySelectorAll('a[href*="#"]')).forEach(function (anchor) {
            anchor.addEventListener('click', function (e) {
                self.anchorScroll = true;
                self.scrollToAnchor(e, anchor.getAttribute('href'));
                e.preventDefault();
            });
        });
        if (window.location.hash) {
            window.addEventListener('load', this.scrollToAnchor.bind(this));
        }
    }
    scrollToAnchor(evt, anchor) {
        if (anchor == undefined) {
            anchor = window.location.hash;
        }
        var self = this;
        let a = anchor.split('#')[1];
        if (a != null && a != undefined) {
            //if (document.querySelectorAll("#" + a).length > 0) {
            let p = document.getElementById(a).getBoundingClientRect().top + window.scrollY - 100;
            gsap.to(window, { duration: 1.4, scrollTo: p, ease: "power3.out", onComplete: self.resetAnchorSCroll, onCompleteParams: [self] });
            //}
        }
    }
    resetAnchorSCroll(self) {
        self.anchorScroll = false;
    }
}